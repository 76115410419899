import React from 'react';
import {seo_titles} from "../../language/seo_titles";
import MetaTags from "react-meta-tags";
import {useParams} from "react-router-dom";

export const SeoTagsStatic = ({ page }) => {
    const { lang } = useParams();
    const { key } = useParams();
    if (!page) return;
    return(
        <MetaTags>
            <title>{`${page === 'search' ? key : ''}${seo_titles[lang || 'ua'][page]}`}</title>
            {/*<meta name="description" content={props.product.seo.description} />*/}
            {/*<meta name="keywords" content={props.product.seo.keywords} />*/}
        </MetaTags>
    );
}