export const seo_titles = {
    ru: {
        search: 'Поиск экспресс-тестов в магазине медицинских экспресc-тестов | MedBuy',
        home: 'Магазин для лабораторной диагностики | MedBuy',
        partner_program: 'Партнерская программа для медиков | MedBuy',
        payment_information: 'Заказ, оплата и доставка экспресс-тестов | MedBuy',
        about: 'О нас | MedBuy',
        login: 'Авторизация в магазине медицинских экспресc-тестов | MedBuy',
        confirm: 'Оформление заказа в магазине медицинских экспресc-тестов | MedBuy',
        checkout: 'Оплата заказа в магазине медицинских экспресc-тестов | MedBuy',
        account: 'Личный кабинет в магазине медицинских экспресc-тестов | MedBuy',
    },
    ua: {
        search: ' в магазині медичних експрес-тестів | MedBuy',
        home: 'Магазин для лабораторної діагностики | MedBuy',
        partner_program: 'Партнерська програма для медиків | MedBuy',
        payment_information: 'Замовлення, оплата та доставка експрес-тестів | MedBuy',
        about: 'Про нас | MedBuy',
        login: 'Авторизація в магазині медичних експрес-тестів | MedBuy',
        confirm: 'Оформлення замовлення в магазині медичних експрес-тестів | MedBuy',
        checkout: 'Оплата замовлення в магазині медичних експрес-тестів | MedBuy',
        account: 'Особистий кабінет в магазині медичних експрес-тестів | MedBuy',
    },
    en: {
        search: 'Look Up a Rapid Test in the Rapid Diagnostic Tests Store | MedBuy',
        home: 'Shop for laboratory diagnostics | MedBuy',
        partner_program: 'Partner Program for Health Professionals | MedBuy',
        payment_information: 'Rapid Test Ordering, Payment and Shipping | MedBuy',
        about: 'About Us | MedBuy',
        login: 'Login - Rapid Diagnostic Tests Store | MedBuy',
        confirm: 'Ordering with the Rapid Diagnostic Tests Store | MedBuy',
        checkout: 'Order Payment at the Rapid Diagnostic Tests Store | MedBuy',
        account: 'Personal Account with the Rapid Diagnostic Tests Store | MedBuy',
    },
};