import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toggleActiveMenuItemAC} from "../../store/reducers/MainReducer";
import ImageSearch from "../../image/search.png";
import LangLink from "../Common/LangLink";

const MenuMobile = ({items,searchInput,changeSearch,getTranslate}) => {
    const activeMenu = useSelector((state) => state.main.menuActive);
    const dispatch = useDispatch();
    const toggleMenu = (id) => dispatch(toggleActiveMenuItemAC(id));
    const [mobileOpen, toggleMobileOpen] = useState(false);
    const [activeCategory, changeActiveCategory] = useState(null);

    const handlerTabChange = (e) => {
        changeActiveCategory(e.id);
    };

    const categoriesList = {};
    const handlerCategoriesList = (e) => {
        categoriesList[e.alias] = e;
        if (e?.childs?.length > 0) {
            e?.childs?.forEach(handlerCategoriesList);
        }
    };

    const renderCategories = () => {
        if(activeCategory) {
            items?.forEach(handlerCategoriesList);
            const category = Object.values(categoriesList).find(e => Number(e.id) === Number(activeCategory));
            if(category) {
                return <>
                    <a className={"col-md-6"} href={'/category/' + category.alias}>{getTranslate('front_menu','menu_mobile_all_product')} {category.name}</a>
                    <div className={"row row_wrap"}>
                        {category?.childs
                            .map(e => {
                                return e?.childs?.length > 0 ?
                                    <div className={"col-md-6 category_childs_true"} onClick={() => handlerTabChange(e)}>
                                        {e.name}
                                    </div>
                                    :
                                    <a className={"col-md-6"} href={'/category/' + e.alias} onClick={() => toggleMobileOpen(false)}> {e.name} </a>
                            })}
                    </div>
                </>
            }
        } else {
            return items.map(e => {
                return e?.childs?.length > 0 ?
                    <div className={"col-md-6 category_childs_true"} onClick={() => changeActiveCategory(e.id)}>
                        {e.name}
                    </div>
                    :
                    <a className={"col-md-6"} href={'/category/' + e.alias} onClick={() => toggleMobileOpen(false)}> {e.name} </a>
            })
        }

    }
    
    return <>
        <nav className={"mobile_menu_container"}>
        <div onClick={() => toggleMobileOpen(!mobileOpen)}>
            <i className="fas fa-bars text-white"></i>
        </div>
        <form className="header_search" onSubmit={(event) => {
            event.preventDefault();
            window.location.href = '/search/' + searchInput.replace('/', ' ');
        }}>
            <input className="form-control" value={searchInput} onChange={(event) => changeSearch(event.target.value)}/>
            <LangLink to={'/search/' + searchInput.replace('/', ' ')} className="header_form_button" type="submit"><img src={ImageSearch} /></LangLink>
        </form>
        {mobileOpen && <div className="main_menu_container_mobile container">
            <div className={'main_menu_container_mobile_close'} onClick={() => toggleMobileOpen(false)}>
                <span>⤫</span>
            </div>
            {activeMenu === 'category' ?
                <>
                    <div className={"row color_change_menu_category"}>
                        {renderCategories()}
                    </div>
                </>
                :
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <LangLink className={'menu_item_title ' + (activeMenu === 'home' ? 'active' : '')}
                                  onClick={() => {
                                      toggleMenu(activeMenu === 'home' ? null : 'home');
                                      toggleMobileOpen(false);
                                  }}
                                  to={'/'}
                            >
                                <span><i className="fas fa-home"></i> {getTranslate('front_menu','menu_home_title')}</span>
                            </LangLink>
                        </div>
                        <div className="col-md-6">
                            <div className={'menu_item_title ' + (activeMenu === 'category' ? 'active' : '')}
                                 onClick={() => toggleMenu(activeMenu === 'category' ? null : 'category')}>
                                {getTranslate('front_menu','menu_catalog_title')}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <LangLink className={'menu_item_title ' + (activeMenu === 'partner_program' ? 'active' : '')}
                                  onClick={() => {
                                      toggleMenu(activeMenu === 'partner_program' ? null : 'partner_program');
                                      toggleMobileOpen(false);
                                  }}
                                  to={'/partner_program'}
                            >
                                <span>{getTranslate('front_menu','menu_partner_program_title')}</span>
                            </LangLink>
                        </div>
                        <div className="col-md-6">
                            <LangLink className={'menu_item_title ' + (activeMenu === 'payment_information' ? 'active' : '')}
                                  onClick={() => {
                                      toggleMenu(activeMenu === 'payment_information' ? null : 'about');
                                      toggleMobileOpen(false);
                                  }}
                                  to={'/payment_information'}
                            >
                                <span>{getTranslate('front_menu','menu_delivery_title')}</span>
                            </LangLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <LangLink className={'menu_item_title ' + (activeMenu === 'about' ? 'active' : '')}
                                  onClick={() => {
                                      toggleMenu(activeMenu === 'about' ? null : 'about');
                                      toggleMobileOpen(false);
                                  }}
                                  to={'/about'}
                            >
                                <span>{getTranslate('front_menu','menu_about_us_title')}</span>
                            </LangLink>
                        </div>
                    </div>
                </>
            }
        </div>}
    </nav>
        {/*<div className="row header-top-bar">*/}
        {/*    Шановні клієнти, звертаємо вашу увагу на тимчасову відсутність технічної можливості оплати замовлень картою на нашому сайті. Ми вже працюємо над вирішенням проблеми і найближчим часом така можливість буде відновлена. Наразі просимо при розміщенні замовлення обирати оплату безготівковим переказом. Такий переказ можна виконати в переважній більшості банківських додатків. Будь ласка, зв'яжіться з нами, якщо вам потрібна допомога в проведенні такої безготівкової оплати.*/}
        {/*</div>*/}
    </>
}

export default MenuMobile;